import { createModel } from "@/util/ModelUtil";

export default class PageDataModel<T> {
  constructor(
    public data: T[] = [],
    public last: boolean = false,
    public pageNumber: number = 0,
    public totalElementCount: number = 0
  ) {}

  public static create<T>(
    pageData?: PageData<T>,
    create?: (item: T) => T
  ): PageDataModel<T> {
    if (create && pageData) {
      pageData.data = pageData?.data.map(item => create(item));
    }
    return createModel<PageDataModel<T>>(PageDataModel, pageData);
  }
}
