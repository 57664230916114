export enum RouterMenuName {
  // Home = "Home",
  SignIn = "SignIn",
  UserList = "UserList",
  UserDetail = "UserDetail",
  UserAddedPage = "UserAddedPage",
  Course = "Course",
  CourseDetail = "CourseDetail",
  CreateCourseWithExcel = "CreateCourseWithExcel",
  Chapter = "Chapter",
  Tag = "Tag",
  TagDetail = "TagDetail",
  CreateTag = "CreateTag",
  CoursePromotion = "CoursePromotion",
  CoursePromotionDetail = "CoursePromotionDetail",
  CreateCoursePromotion = "CreateCoursePromotion",
  KDigitalCreditUserList = "KDigitalCreditUserList",
  Blog = "Blog",
  BlogDetail = "BlogDetail",
  BlogCreate = "BlogCreate",
  Lecture = "Lecture",
  CreateLecture = "CreateLecture",
  LectureDetail = "LectureDetail",
  SpecialTrack = "SpecialTrack",
  SpecialTrackDetail = "SpecialTrackDetail",
  SpecialTrackCreation = "SpecialTrackCreation",
  Community = "Community",
  CommunityCategory = "CommunityCategory",
  ServiceCenter = "ServiceCenter",
  ServiceCenterFaq = "ServiceCenterFaq",
  ServiceCenterFaqQuestion = "ServiceCenterFaqQuestion",
  ServiceCenterFaqQuestionCreate = "ServiceCenterFaqQuestionCreate",
  Subscribe = "Subscribe",
  Ticket = "Ticket",
  TicketCreate = "TicketCreate",
  Product = "Product",
  ProductCreate = "ProductCreate",
  Infra = "Infra",
  InfraCreate = "InfraCreate",
  Coupon = "Coupon",
  CouponCreate = "CouponCreate",
  ReferenceRoom = "ReferenceRoom",
  ReferenceRoomCreate = "ReferenceRoomCreate",
  PaymentHistory = "PaymentHistory",
  QueryData = "QueryData"
}
