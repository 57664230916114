import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { NAV_TYPE_SIDE, SIDE_NAV_LIGHT } from "@/const/ThemeConstant";

@Module({ namespaced: true, name: "theme" })
export default class Theme extends VuexModule {
  public navCollapsed = false;
  public sideNavTheme: string = SIDE_NAV_LIGHT;
  public locale = "en";
  public navType: string = NAV_TYPE_SIDE;
  public topNavColor = "#3e82f7";
  public headerNavColor = "#ffffff";
  public mobileNav = false;

  @Mutation
  public setNavCollapsed(navCollapsed: boolean): void {
    this.navCollapsed = navCollapsed;
  }
}
