import moment from "moment";

export default function(
  date: Date | null | undefined | number,
  emptyText = ""
): string {
  if (!date) {
    return emptyText;
  }
  return moment(date, "YYYY-MM-DD HH:mm").format("YYYY.MM.DD");
}
