import Vue from "vue";

import HtmlNewLine from "@/filter/HtmlNewLine";
import CommaNumber from "@/filter/CommaNumber";
import RelativeTime from "@/filter/RelativeTime";
import DotDate from "@/filter/DotDate";
import BooleanToYN from "@/filter/BooleanToYN";
import FixedNumber from "@/filter/FixedNumber";
import FixedScore from "@/filter/FixedScore";
import DotTimestamp from "@/filter/DotTimestamp";
import KorDate from "@/filter/KorDate";
import ConvertEmptyString from "@/filter/ConvertEmptyString";

export const filter: Record<string, Function> = {
  HtmlNewLine: HtmlNewLine,
  CommaNumber: CommaNumber,
  RelativeTime: RelativeTime,
  DotDate: DotDate,
  BooleanToYN,
  FixedNumber,
  FixedScore,
  DotTimestamp: DotTimestamp,
  KorDate,
  ConvertEmptyString
};

Object.keys(filter).forEach((key: string): void => {
  Vue.filter(key, filter[key]);
});
