import { AxiosInstance, AxiosRequestConfig } from "axios";
import { connector as HttpConnector } from "@/service/connector";
import { defer, Observable, of, throwError } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { getCookie } from "@/util/CookieUtil";
import { CookieType } from "@/enum/CookieType";

export default class ApiService {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  protected get<T>(
    url: string,
    params?: { [key: string]: any },
    includeToken = false
  ): Observable<T> {
    const headers: AxiosRequestConfig = this.makeRequestHeaders(includeToken);
    return defer(() => this.connector.get<T>(url, { headers, params })).pipe(
      switchMap((result: Response | any) => {
        return result.code == 200 ? of(result) : throwError(result);
      }),
      map(result => result.data)
    );
  }

  protected post<T>(
    url: string,
    data?: { [key: string]: any },
    includeToken = false,
    isFormDataType = false
  ): Observable<T> {
    const headers: AxiosRequestConfig = this.makeRequestHeaders(
      includeToken,
      isFormDataType
    );
    return defer(() => this.connector.post<T>(url, data, { headers })).pipe(
      switchMap((result: Response | any) => {
        return result.code == 200 ? of(result) : throwError(result);
      }),
      map(result => result.data)
    );
  }

  protected put<T>(
    url: string,
    data?: { [key: string]: any },
    includeToken = false,
    isFormDataType = false
  ): Observable<T> {
    const headers: AxiosRequestConfig = this.makeRequestHeaders(
      includeToken,
      isFormDataType
    );
    return defer(() => this.connector.put<T>(url, data, { headers })).pipe(
      switchMap((result: Response | any) => {
        return result.code == 200 ? of(result) : throwError(result);
      }),
      map(result => result.data)
    );
  }

  protected delete<T>(
    url: string,
    params?: { [key: string]: any },
    includeToken = false,
    isFormDataType = false
  ): Observable<T> {
    const headers: AxiosRequestConfig = this.makeRequestHeaders(
      includeToken,
      isFormDataType
    );
    return defer(() =>
      this.connector.delete<T>(url, { headers, params, data: params })
    ).pipe(
      switchMap((result: Response | any) => {
        return result.code == 200 ? of(result) : throwError(result);
      }),
      map(result => result.data)
    );
  }

  protected get applicationContentType(): string {
    return "application/json";
  }

  protected get formDataContentType(): string {
    return "multipart/form-data";
  }

  private get connector(): AxiosInstance {
    return HttpConnector.connector;
  }

  private makeRequestHeaders(
    includeToken: boolean,
    isFormDataType = false
  ): { [key: string]: string } {
    const headers: { [key: string]: string } = {
      "content-type": isFormDataType
        ? this.formDataContentType
        : this.applicationContentType
    };
    if (includeToken) {
      headers.authorization = `Bearer ${getCookie(CookieType.AccessToken)}`;
    }
    return headers;
  }
}
