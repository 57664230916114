import { RouterMenuName } from "@/enum/RouterMenuName";
import { menu } from "@/router/menu";
import { LayoutType } from "@/enum/LayoutType";
import { RouteConfig } from "vue-router";
import RouterMeta from "@/router/model/RouterMeta.ts";
import { APP_PREFIX_PATH } from "@/config/AppConfig";

const meta: RouterMeta = {
  layout: LayoutType.APP,
  guard: {
    login: true
  }
};

export const app: RouteConfig = {
  path: APP_PREFIX_PATH,
  component: menu.layout.appLayout,
  children: [
    // {
    //   path: "home",
    //   component: menu.home.main,
    //   name: RouterMenuName.Home,
    //   meta
    // },
    {
      path: "user",
      component: menu.user.main,
      name: RouterMenuName.UserList,
      meta
    },
    {
      path: "user/userAdded",
      component: menu.user.added,
      name: RouterMenuName.UserAddedPage,
      meta
    },
    {
      path: "member/:id",
      component: menu.user.detail,
      name: RouterMenuName.UserDetail,
      meta
    },
    {
      path: "course",
      component: menu.course.main,
      name: RouterMenuName.Course,
      meta
    },
    {
      path: "course/detail/:id",
      component: menu.course.detail,
      name: RouterMenuName.CourseDetail,
      meta
    },
    {
      path: "course/create-with-excel",
      component: menu.course.createWithExcel,
      name: RouterMenuName.CreateCourseWithExcel,
      meta
    },
    {
      path: "chapter",
      component: menu.chapter.main,
      name: RouterMenuName.Chapter,
      meta
    },
    {
      path: "lecture",
      component: menu.lecture.main,
      name: RouterMenuName.Lecture,
      meta
    },
    {
      path: "lecture/detail/:id",
      component: menu.lecture.detail,
      name: RouterMenuName.LectureDetail,
      meta
    },
    {
      path: "lecture/create",
      component: menu.lecture.create,
      name: RouterMenuName.CreateLecture,
      meta
    },
    {
      path: "tag",
      component: menu.tag.main,
      name: RouterMenuName.Tag,
      meta
    },
    {
      path: "tag/detail/:id",
      component: menu.tag.detail,
      name: RouterMenuName.TagDetail,
      meta
    },
    {
      path: "tag/create",
      component: menu.tag.create,
      name: RouterMenuName.CreateTag,
      meta
    },
    {
      path: "course-promotion",
      component: menu.coursePromotion.main,
      name: RouterMenuName.CoursePromotion,
      meta
    },
    {
      path: "course-promotion/detail/:id",
      component: menu.coursePromotion.detail,
      name: RouterMenuName.CoursePromotionDetail,
      meta
    },
    {
      path: "course-promotion/create",
      component: menu.coursePromotion.create,
      name: RouterMenuName.CreateCoursePromotion,
      meta
    },
    {
      path: "kDigitalCredit/user",
      component: menu.kDigitalCredit.user,
      name: RouterMenuName.KDigitalCreditUserList,
      meta
    },
    {
      path: "blog",
      component: menu.blog.main,
      name: RouterMenuName.Blog,
      meta
    },
    {
      path: "blog/detail/:id",
      component: menu.blog.detail,
      name: RouterMenuName.BlogDetail,
      meta
    },
    {
      path: "blog/create",
      component: menu.blog.create,
      name: RouterMenuName.BlogCreate,
      meta
    },
    {
      path: "specialTrack",
      component: menu.specialTrack.main,
      name: RouterMenuName.SpecialTrack,
      meta
    },
    {
      path: "specialTrack/detail/:id",
      component: menu.specialTrack.detail,
      name: RouterMenuName.SpecialTrackDetail,
      meta
    },
    {
      path: "specialTrack/create",
      component: menu.specialTrack.create,
      name: RouterMenuName.SpecialTrackCreation,
      meta
    },
    {
      path: "community",
      component: menu.community.main,
      name: RouterMenuName.Community,
      meta
    },
    {
      path: "communityCategory",
      component: menu.community.category,
      name: RouterMenuName.CommunityCategory,
      meta
    },
    {
      path: "serviceCenter",
      component: menu.serviceCenter.main,
      name: RouterMenuName.ServiceCenter,
      meta
    },
    {
      path: "serviceCenterFaq",
      component: menu.serviceCenter.faq,
      name: RouterMenuName.ServiceCenterFaq,
      meta
    },
    {
      path: "serviceCenterFaqQuestion",
      component: menu.serviceCenter.question,
      name: RouterMenuName.ServiceCenterFaqQuestion,
      meta
    },
    {
      path: "serviceCenterFaqQuestion/create",
      component: menu.serviceCenter.questionCreate,
      name: RouterMenuName.ServiceCenterFaqQuestionCreate,
      meta
    },
    {
      path: "subscribe",
      component: menu.subscribe.main,
      name: RouterMenuName.Subscribe,
      meta
    },
    {
      path: "ticket",
      component: menu.ticket.main,
      name: RouterMenuName.Ticket,
      meta
    },
    {
      path: "ticket/create",
      component: menu.ticket.create,
      name: RouterMenuName.TicketCreate,
      meta
    },
    {
      path: "product",
      component: menu.product.main,
      name: RouterMenuName.Product,
      meta
    },
    {
      path: "product/create",
      component: menu.product.create,
      name: RouterMenuName.ProductCreate,
      meta
    },
    {
      path: "infra",
      component: menu.infra.main,
      name: RouterMenuName.Infra,
      meta
    },
    {
      path: "infra/create",
      component: menu.infra.create,
      name: RouterMenuName.InfraCreate,
      meta
    },
    {
      path: "coupon",
      component: menu.coupon.main,
      name: RouterMenuName.Coupon,
      meta
    },
    {
      path: "coupon/create",
      component: menu.coupon.create,
      name: RouterMenuName.CouponCreate,
      meta
    },
    {
      path: "referenceRoom",
      component: menu.referenceRoom.main,
      name: RouterMenuName.ReferenceRoom,
      meta
    },
    {
      path: "referenceRoom/create",
      component: menu.referenceRoom.create,
      name: RouterMenuName.ReferenceRoomCreate,
      meta
    },
    {
      path: "payment-history",
      component: menu.paymentHistory.main,
      name: RouterMenuName.PaymentHistory,
      meta
    },
    {
      path: "queryData",
      component: menu.queryData.main,
      name: RouterMenuName.QueryData,
      meta
    },
    {
      path: "",
      // redirect: { name: RouterMenuName.Home }
      redirect: { name: RouterMenuName.UserList }
    }
  ]
};

export const signin: RouteConfig = {
  path: "/signin",
  component: menu.signIn.main,
  name: RouterMenuName.SignIn,
  meta: {
    guard: {
      login: false
    }
  }
};
