export enum UserStatusType {
  NORMAL = "NORMAL",
  LEAVE = "LEAVE",
  SLEEP = "SLEEP",
  SUSPEND = "SUSPEND"
}

export enum UseStatusTypeName {
  NORMAL = "정상",
  LEAVE = "탈퇴",
  SLEEP = "휴먼",
  SUSPEND = "정지"
}
