import { AsyncComponent } from "vue";
type menuContent = { [key: string]: AsyncComponent };
// eslint-disable-next-line
export const menu: { [key: string]: menuContent | any } = {
  layout: {
    appLayout: () =>
      import(/* webpackChunkName: "AppLayout" */ "@/views/layout/AppLayout.vue")
  },
  // home: {
  //   main: () => import(/* webpackChunkName: "Home" */ "@/views/home/Home.vue")
  // },
  signIn: {
    main: () =>
      import(/* webpackChunkName: "SignIn" */ "@/views/signIn/SignIn.vue")
  },
  user: {
    main: () =>
      import(/* webpackChunkName: "User" */ "@/views/user/list/UserList.vue"),
    detail: () =>
      import(
        /* webpackChunkName: "UserDetail" */ "@/views/user/detail/UserDetail.vue"
      ),
    added: () =>
      import(
        /* webpackChunkName: "UserAdded" */ "@/views/user/Added/UserAddedPage.vue"
      )
  },
  course: {
    main: () =>
      import(/* webpackChunkName: "Course" */ "@/views/course/CourseMain.vue"),
    detail: () =>
      import(
        /* webpackChunkName: "CourseDetail" */ "@/views/course/CourseDetail.vue"
      ),
    createWithExcel: () =>
      import(
        /* webpackChunkName: "CreateCourseWithExcel" */ "@/views/course/CreateCourseWithExcel.vue"
      )
  },
  chapter: {
    main: () =>
      import(
        /* webpackChunkName: "Chapter" */ "@/views/chapter/ChapterMain.vue"
      )
  },
  lecture: {
    main: () =>
      import(
        /* webpackChunkName: "Lecture" */ "@/views/lecture/LectureMain.vue"
      ),
    detail: () =>
      import(
        /* webpackChunkName: "LectureDetail" */ "@/views/lecture/LectureDetail.vue"
      ),
    create: () =>
      import(
        /* webpackChunkName: "CreateLecture" */ "@/views/lecture/CreateLecture.vue"
      )
  },
  tag: {
    main: () => import(/* webpackChunkName: "Tag" */ "@/views/tag/TagMain.vue"),
    detail: () =>
      import(/* webpackChunkName: "TagDetail" */ "@/views/tag/TagDetail.vue"),
    create: () =>
      import(/* webpackChunkName: "CreateTag" */ "@/views/tag/CreateTag.vue")
  },
  coursePromotion: {
    main: () =>
      import(
        /* webpackChunkName: "CoursePromotion" */ "@/views/coursePromotion/CoursePromotionMain.vue"
      ),
    detail: () =>
      import(
        /* webpackChunkName: "CoursePromotionDetail" */ "@/views/coursePromotion/CoursePromotionDetail.vue"
      ),
    create: () =>
      import(
        /* webpackChunkName: "CreateCoursePromotion" */ "@/views/coursePromotion/CreateCoursePromotion.vue"
      )
  },
  kDigitalCredit: {
    user: () =>
      import(
        /* webpackChunkName: "KDigitalCreditUser" */ "@/views/kDigitalCredit/userList/KDigitalCreditUser.vue"
      )
  },
  blog: {
    main: () =>
      import(/* webpackChunkName: "Blog" */ "@/views/blog/BlogMain.vue"),
    create: () =>
      import(
        /* webpackChunkName: "BlogCreate" */ "@/components/blog/BlogCreate.vue"
      )
  },
  specialTrack: {
    main: () =>
      import(
        /* webpackChunkName: "SpecialTrack" */ "@/views/specialTrack/SpecialTrackMain.vue"
      ),
    create: () =>
      import(
        /* webpackChunkName: "CreateSpecialTrack" */ "@/components/specialTrack/create/SpecialTrackCreate.vue"
      )
  },
  community: {
    main: () =>
      import(
        /* webpackChunkName: "Community" */ "@/views/community/CommunityMain.vue"
      ),
    category: () =>
      import(
        /* webpackChunkName: "CommunityCategoryMain" */ "@/views/community/CommunityCategoryMain.vue"
      )
  },
  serviceCenter: {
    main: () =>
      import(
        /* webpackChunkName: "ServiceCenter" */ "@/views/serviceCenter/ServiceCenterOnOInquiryMain.vue"
      ),
    faq: () =>
      import(
        /* webpackChunkName: "ServiceCenterFaq" */ "@/views/serviceCenter/ServiceCenterFaqMain.vue"
      ),
    question: () =>
      import(
        /* webpackChunkName: "ServiceCenterFaqQuestion" */ "@/components/serviceCenter/faq/question/list/ServiceCenterFaqQuestionList.vue"
      ),
    questionCreate: () =>
      import(
        /* webpackChunkName: "ServiceCenterFaqQuestionCreate" */ "@/components/serviceCenter/faq/question/create/ServiceCenterFaqQuestionCreate.vue"
      )
  },
  subscribe: {
    main: () =>
      import(
        /* webpackChunkName: "ServiceCenter" */ "@/views/subscribe/SubscribeMain.vue"
      )
  },
  ticket: {
    main: () =>
      import(
        /* webpackChunkName: "TicketMain" */ "@/views/ticket/TicketMain.vue"
      ),
    create: () =>
      import(
        /* webpackChunkName: "TicketCreate" */ "@/components/ticket/create/TicketCreate.vue"
      )
  },
  product: {
    main: () =>
      import(
        /* webpackChunkName: "ProductMain" */ "@/views/product/ProductMain.vue"
      ),
    create: () =>
      import(
        /* webpackChunkName: "ProductCreate" */ "@/components/product/create/ProductCreate.vue"
      )
  },
  infra: {
    main: () =>
      import(/* webpackChunkName: "InfraMain" */ "@/views/infra/InfraMain.vue")
  },
  coupon: {
    main: () =>
      import(
        /* webpackChunkName: "CouponMain" */ "@/views/coupon/CouponMain.vue"
      ),
    create: () =>
      import(
        /* webpackChunkName: "CouponCreate" */ "@/components/coupon/create/CouponCreate.vue"
      )
  },
  referenceRoom: {
    main: () =>
      import(
        /* webpackChunkName: "ReferenceRoomMain" */ "@/views/referenceRoom/ReferenceRoomMain.vue"
      ),
    create: () =>
      import(
        /* webpackChunkName: "ReferenceRoomCreate" */ "@/components/referenceRoom/create/ReferenceRoomCreate.vue"
      )
  },
  paymentHistory: {
    main: () =>
      import(
        /* webpackChunkName: "paymentHistoryMain" */ "@/views/paymentHistory/PaymentHistoryMain.vue"
      )
  },
  queryData: {
    main: () =>
      import(
        /* webpackChunkName: "queryDataMain" */ "@/views/queryData/QueryDataMain.vue"
      )
  }
};
