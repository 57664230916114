export enum UserProviderType {
  local = "local",
  google = "google",
  kakao = "kakao",
  naver = "naver"
}

export enum UserProviderTypeName {
  local = "CodePresso",
  google = "Google",
  kakao = "Kakao",
  naver = "Naver"
}
