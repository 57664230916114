import { createModel } from "@/util/ModelUtil";
import UserModel from "@/service/model/user/UserModel";
import OrganizationModel from "@/service/model/organization/OrganizationModel";

export default class UserDetailModel {
  constructor(
    public user: User = new UserModel(),
    public organization: Organization = new OrganizationModel()
  ) {}

  public static create(data?: UserDetail): UserDetailModel {
    return createModel<UserDetailModel>(UserDetailModel, data);
  }
}
