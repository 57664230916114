import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import UserModel from "@/service/model/user/UserModel";

@Module({ namespaced: true, name: "user" })
export default class User extends VuexModule {
  // public userId = 0;
  // public userProfileImageUrl = "";
  // public userNickName = "";
  // public organizationId = 0;
  public user = new UserModel();

  @Mutation
  public setUser(user: UserModel): void {
    // this.userId = user.id;
    // this.userProfileImageUrl = user.profileImageUrl;
    // this.userNickName = user.nickName;
    // this.organizationId = user.organizationId;
    this.user = user;
  }
}
