import VueRouter, { RouteConfig } from "vue-router";
import { RouterMenuName } from "@/enum/RouterMenuName";
import { app, signin } from "@/router/app";

const routes: Array<RouteConfig> = [
  app,
  signin,
  {
    path: "*",
    // redirect: { name: RouterMenuName.Home }
    redirect: { name: RouterMenuName.UserList }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  router.fromRouterName = from.name as string;
  router.fromNeedLogin = !!from.meta?.guard?.login;

  next();
});

export { router, routes };
