export default class ConnectorError {
  public static errorMessage: { [key: string]: string } = {
    USER_SELECT: "유저 검색",
    USER_CREATE: "유저 생성",
    USER_UPDATE: "유저 수정",
    USER_DELETE: "유저 삭제",
    COURSE_SELECT: "코스 검색",
    COURSE_CREATE: "코스 생성",
    COURSE_UPDATE: "코스 수정",
    COURSE_DELETE: "코스 삭제",
    K_DIGITAL_SELECT: "K-디지털 검색",
    K_DIGITAL_CREATE: "K-디지털 생성",
    K_DIGITAL_UPDATE: "K-디지털 수정",
    K_DIGITAL_DELETE: "K-디지털 삭제",
    BLOG_SELECT: "블로그 검색",
    BLOG_CREATE: "블로그 생성",
    BLOG_UPDATE: "블로그 수정",
    BLOG_DELETE: "블로그 삭제",
    SPECIAL_TRACK_SELECT: "스페셜 트랙 검색",
    SPECIAL_TRACK_CREATE: "스페셜 트랙 생성",
    SPECIAL_TRACK_UPDATE: "스페셜 트랙 수정",
    SPECIAL_TRACK_DELETE: "스페셜 트랙 삭제",
    COMMUNITY_SELECT: "커뮤니티 검색",
    COMMUNITY_CREATE: "커뮤니티 생성",
    COMMUNITY_UPDATE: "커뮤니티 수정",
    COMMUNITY_DELETE: "커뮤니티 삭제",
    SERVICE_CENTER_SELECT: "고객센터 검색",
    SERVICE_CENTER_CREATE: "고객센터 생성",
    SERVICE_CENTER_UPDATE: "고객센터 수정",
    SERVICE_CENTER_DELETE: "고객센터 삭제",
    SUBSCRIBE_SELECT: "구독 검색",
    SUBSCRIBE_CREATE: "구독 생성",
    SUBSCRIBE_UPDATE: "구독 수정",
    SUBSCRIBE_DELETE: "구독 삭제",
    TICKET_SELECT: "티켓 검색",
    TICKET_CREATE: "티켓 생성",
    TICKET_UPDATE: "티켓 수정",
    TICKET_DELETE: "티켓 삭제",
    PRODUCT_SELECT: "상품 검색",
    PRODUCT_CREATE: "상품 생성",
    PRODUCT_UPDATE: "상품 수정",
    INFRA_SELECT: "인프라 검색",
    INFRA_CREATE: "인프라 생성",
    INFRA_UPDATE: "인프라 수정",
    COUPON_SELECT: "쿠폰 검색",
    COUPON_CREATE: "쿠폰 생성",
    COUPON_UPDATE: "쿠폰 수정",
    COUPON_DELETE: "쿠폰 삭제",
    ARCHIVE_SELECT: "자료실 검색",
    ARCHIVE_CREATE: "자료실 생성",
    ARCHIVE_UPDATE: "자료실 수정",
    ARCHIVE_DELETE: "자료실 삭제",
    SURVEY_SELECT: "설문 검색",
    SURVEY_CREATE: "설문 생성",
    SURVEY_UPDATE: "설문 수정",
    SURVEY_DELETE: "설문 삭제",
    FAQ_SELECT: "FAQ 검색",
    FAQ_CREATE: "FAQ 생성",
    FAQ_UPDATE: "FAQ 수정",
    FAQ_DELETE: "FAQ 검색",
    PAYMENT_SELECT: "결제내역 검색",
    PAYMENT_CREATE: "결제내역 생성"
  };
}
