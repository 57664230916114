import { createModel } from "@/util/ModelUtil";
import { UserType } from "@/enum/user/UserType";
import { UserGender } from "@/enum/UserGender";
import { UserStatusType } from "@/enum/user/UserStatusType";
import { UserProviderType } from "@/enum/user/UserProviderType";

export default class UserModel {
  constructor(
    public id: number = 0,
    public email: string = "",
    public emailAuthFlag: boolean = false,
    public name: string = "",
    public nickName: string = "",
    public profileImageUrl: string = "",
    public provider: UserProviderType = UserProviderType.local,
    public introduction: string = "",
    public organizationId: number = 0,
    public type: UserType = UserType.NORMAL,
    public birthday: string = "",
    public gender: UserGender = UserGender.M,
    public organizationName: string = "",
    public department: string = "",
    public phone: string = "",
    public emailMarketingTermsFlag: boolean = false,
    public smsMarketingTermsFlag: boolean = false,
    public status: UserStatusType = UserStatusType.NORMAL,
    public recentAccessDate: number = new Date().getDate(),
    public createdAt: number = new Date().getDate(),
    public updatedAt: number = new Date().getDate()
  ) {}

  public static create(user?: User): UserModel {
    return createModel<UserModel>(UserModel, user);
  }
}
