import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true, name: "loading" })
export default class Loading extends VuexModule {
  public isOpen = false;

  @Mutation
  public open(): void {
    this.isOpen = true;
  }

  @Mutation
  public close(): void {
    this.isOpen = false;
  }
}
