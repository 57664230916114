import User from "@/store/module/User";
import Theme from "@/store/module/Theme";
import Confirm from "@/store/module/Confirm";
import Loading from "@/store/module/Loading";

export const storeOptions = {
  modules: {
    user: User,
    theme: Theme,
    confirm: Confirm,
    loading: Loading
  }
};
