import ApiService from "@/service/api/ApiService";
import { catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import UserModel from "@/service/model/user/UserModel";
import UserDetailModel from "@/service/model/user/UserDetailModel";
import PageDataModel from "@/service/model/common/PageDataModel";
import { UserType } from "@/enum/user/UserType";

class UserService extends ApiService {
  /**
   * 로그인
   * @param email
   * @param password
   */
  public signIn(email: string, password: string): Observable<string> {
    return this.post<string>("/user/signin", { email, password }).pipe(
      map((token: string) => token),
      catchError(() => of(""))
    );
  }

  /**
   * 현재 로그인 된 사용자 정보 조회
   */
  public retrieveSignInUser(): Observable<UserModel> {
    return this.get<User>("/user", {}, true).pipe(
      map((user: User) => UserModel.create(user)),
      catchError(() => of(new UserModel()))
    );
  }

  /**
   * 전체 일반회원 목록 조회
   * @param page
   * @param itemSize
   * @param userType
   * @param userStatusType
   * @param userSearchType
   * @param keyword
   */
  public retrieveUserList(
    page: number,
    itemSize: number,
    userType?: string,
    userStatusType?: string,
    userSearchType?: string,
    keyword?: string
  ): Observable<PageData<UserDetail>> {
    return this.get<PageData<UserDetail>>(
      "/user/list",
      {
        page,
        itemSize,
        userType,
        userStatusType,
        userSearchType,
        keyword
      },
      true
    ).pipe(
      map((page: PageData<UserDetail>) =>
        PageDataModel.create(page, UserDetailModel.create)
      ),
      catchError(() => of(new PageDataModel<UserDetail>()))
    );
  }

  /**
   * 상세 일반회원 목록 조회
   * @param userId
   */

  public retrieveUserDetail(userId: number): Observable<UserDetail> {
    return this.get<UserDetail>("/user/info", { userId }, true).pipe(
      map((userDetail: UserDetail) => UserDetailModel.create(userDetail)),
      catchError(() => of(new UserDetailModel()))
    );
  }

  /**
   * 일반회원 기본 정보 수정
   * @param id
   * @param organizationId
   * @param phone
   * @param type
   * @param name
   */
  public updateUserDefaultInfo(
    id: number,
    organizationId: number,
    phone: string,
    type: UserType,
    name: string
  ): Observable<User> {
    return this.put<User>(
      "/user/info",
      { id, organizationId, phone, type, name },
      true
    ).pipe(
      map((user: User) => UserModel.create(user)),
      catchError(() => of(new UserModel()))
    );
  }

  /**
   * 일반회원 비밀번호 초기화
   * @param id
   * @param password
   */
  public updateToDefaultPassword(
    id: number,
    password: string
  ): Observable<boolean> {
    return this.put<boolean>(
      "/user/password/default",
      { id, password },
      true
    ).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 일반회원 비밀번호 초기화
   * @param id
   * @param emailAuthFlag
   */
  public updateToUserEmailAuthFlag(
    id: number,
    emailAuthFlag: boolean
  ): Observable<boolean> {
    return this.put<boolean>(
      "/user/email-auth",
      { id, emailAuthFlag },
      true
    ).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 계정 등록
   * @param formData
   */
  public createUserData(formData: UserForm): Observable<boolean> {
    return this.post<boolean>("/user", formData, true).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 계정 엑셀 등록
   * @param formData
   */
  public createExcelUserData(formData: FormData): Observable<UserExcelError> {
    return this.post<UserExcelError>("/upload/user-excel", formData, true).pipe(
      map((isSuccess: UserExcelError) => isSuccess)
    );
  }

  /**
   * 계정 Email 중복 검사
   * @param email
   */
  public duplicateEmail(email: string): Observable<boolean> {
    return this.post<boolean>(
      "/user/email-duplicate-check",
      { email: email },
      true
    ).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 계정 NickName 중복 검사
   * @param nickName
   */
  public duplicateNickName(nickName: string): Observable<boolean> {
    return this.post<boolean>(
      "/user/nickname-duplicate-check",
      { nickName: nickName },
      true
    ).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }
}

export const userService = new UserService();
