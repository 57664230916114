import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from "axios";
import Response from "@/service/dto/Response";
import Vue from "vue";
import ConnectorError from "@/service/connector/ConnectorError";

/* eslint-disable  @typescript-eslint/no-explicit-any */
class HttpConnector extends Vue {
  public connector: AxiosInstance = axios.create();

  public setConfig(config: AxiosRequestConfig) {
    this.connector = axios.create(config);
    this.setInterceptors();
  }

  private setInterceptors() {
    this.connector.interceptors.request.use(
      (value: AxiosRequestConfig) => {
        return value;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );

    this.connector.interceptors.response.use(
      (value: AxiosResponse<{ data: Response } | any>) => {
        return value.data;
      },
      (error: AxiosError) => {
        if (error.response?.data.code === 403) {
          if (error.response?.data.data !== "NONE") {
            this.$message.error(
              ConnectorError.errorMessage[error.response?.data.data] +
                " 권한이 없습니다."
            );
          } else {
            this.$message.error("계정의 모든 접근 권한이 없습니다.");
          }
        }
        return Promise.reject(error);
      }
    );
  }
}

export const connector = new HttpConnector();
