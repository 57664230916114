import { isEmpty } from "@/util/ObjectUtil";
/* eslint-disable  @typescript-eslint/no-explicit-any */

// const camelToSnake = (str: string): string =>
//   str.replace(/[A-Z]/g, g => `_${g.toLowerCase()}`);
const camelToKebab = (str: string): string =>
  str.replace(/[A-Z]/g, g => `-${g.toLowerCase()}`);

/**
 * object만 처리, array는 개별 모델 create에서 처리 필요
 * @param Model
 * @param object
 */
export const createModel = <T>(
  Model: new (...args: Array<any>) => T,
  object?: { [key: string]: any }
): T => {
  const model: any = new Model();
  if (!isEmpty(object) && object) {
    Object.keys(model).forEach((key: string): void => {
      if (typeof model[key] === "object" && !Array.isArray(model[key])) {
        model[key] = createModel(model[key].constructor, object[key]);
      } else {
        model[key] =
          object[key] ||
          object[key] === 0 ||
          typeof object[key] === "boolean" ||
          object[key] === null ||
          object[key] === undefined ||
          object[key] === ""
            ? object[key]
            : object[camelToKebab(key)];
      }
    });
  }
  return model;
};
