import Vue, { AsyncComponent, CreateElement } from "vue";
import { router } from "@/router";
import { storeOptions } from "@/store";
import VueRouter from "vue-router";
import Vuex from "vuex";
import { connector } from "@/service/connector";
import { config } from "@/config/axios";
import { filter } from "@/filter";
import Antd, { message } from "ant-design-vue";
import VueApexCharts from "vue-apexcharts";
import UserModel from "@/service/model/user/UserModel";
import { finalize } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { getCookie } from "@/util/CookieUtil";
import { CookieType } from "@/enum/CookieType";
import { userService } from "@/service/api/UserService";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import VueClipboard from "vue-clipboard2";

class Application {
  public vueOptions: Record<string, object> = {};

  constructor() {
    Vue.config.productionTip = false;
    this.enableRouter();
    this.enableAxios();
    this.enableVueX();
    this.enableFilter();
    this.enableAntd();
    this.enableApexCharts();
    this.enableCKEditor();
    this.enableClipboard();
  }

  private enableRouter() {
    Vue.use(VueRouter);
    this.vueOptions.router = router;
  }

  private enableVueX() {
    Vue.use(Vuex);
    this.vueOptions.store = new Vuex.Store(storeOptions);
  }

  private enableAxios() {
    connector.setConfig(config);
  }

  private enableFilter() {
    this.vueOptions.filters = filter;
  }

  private enableAntd() {
    Vue.use(Antd);
    this.setAntdConfig();
  }

  private setAntdConfig() {
    message.config({ top: "100px" });
  }

  private enableApexCharts() {
    Vue.use(VueApexCharts);
  }

  private enableCKEditor() {
    Vue.use(CKEditor);
  }

  private enableClipboard(): void {
    Vue.use(VueClipboard);
  }

  private userLogin(): Observable<UserModel | null> {
    const token: string | undefined = getCookie(CookieType.AccessToken);
    if (token) {
      return userService.retrieveSignInUser();
    }
    return of(null);
  }

  /**
   * 토큰이 있을경우 로그인 후 rendering
   */
  public bootstrap() {
    const app: AsyncComponent = () =>
      import(/* webpackChunkName: "App" */ "@/App.vue");
    this.vueOptions.render = (h: CreateElement) => h(app);
    const vue = new Vue(this.vueOptions);
    this.userLogin()
      .pipe(
        finalize(() => {
          // 회원 조회에 실패해도 rendering 시도
          vue.$mount("#app");
        })
      )
      .subscribe((user: UserModel | null) => {
        if (user) {
          vue.$store.commit("user/setUser", user);
        }
      });
  }
}

const application = new Application();
application.bootstrap();
