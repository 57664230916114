import { createModel } from "@/util/ModelUtil";

export default class OrganizationModel {
  constructor(
    public id: number = 0,
    public name: string = "",
    public companyName: string = "",
    public businessNumber: string = "",
    public code: string = "",
    public email: string = "",
    public maxUser: number = 0,
    public contractTerm: number = new Date().getDate(),
    public createdAt: number = new Date().getDate(),
    public updatedAt: number = new Date().getDate()
  ) {}

  public static create(data?: Organization): OrganizationModel {
    return createModel<OrganizationModel>(OrganizationModel, data);
  }
}
